import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import PortableText from '../components/portableText';
import SEO from '../components/seo';
import {ContactUsButton} from '../components/Buttons/contactUs';


export const query = graphql`
query TermsAndConditionsPageQuery {
  page: sanityTermsAndConditionsPage {
    seoFields {
      description
      title
    }
    title
    _rawMainContent
    mainImageAlt
    mainImage {
      asset {
        fluid(maxWidth: 1920) {
          ...GatsbySanityImageFluid
        }
      }
    }
  }
}

`;

const TermsAndConditionsPage = ({data}) => {
  const {page} = data;
  const {seoFields, title, _rawMainContent} = page;
  const showBanner = page.mainImage === null ? false : true;
  const bannerImgFluid = page.mainImage === null ? {} : page.mainImage.asset.fluid;
  const {mainImageAlt} = page || '';


  return (
    <Layout bannerAlt={mainImageAlt} showBanner={showBanner} bannerImgFluid={bannerImgFluid} bannerTitle={title} >
       <SEO
        title={seoFields.title}
        description={seoFields.description}
        bodyAttr={{
          class: "",
        }}
      />
       <main  >
        <div className="py-16 md:py-24">
        {_rawMainContent &&
          <div className="container textContent mx-auto text-white">
            <PortableText blocks={_rawMainContent} />
            <ContactUsButton/>
          </div>
        }
        </div>
      </main>
    </Layout>
  )
};

export default TermsAndConditionsPage;
